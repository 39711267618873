<template>
    <ModalWrapper
        class="app-confirm-modal"
        #default="{close}"
        :style="{'--modal-window-width': modalWidth}"
    >
        <div class="app-confirm-modal__title">
            <slot name="title" />
        </div>
        <div class="app-confirm-modal__text">
            <slot name="text" />
        </div>
        <div class="app-confirm-modal__actions">
            <template v-if="modalActions.length">
                <button
                    v-for="button in modalActions"
                    :key="button.type"
                    :class="button.className"
                    @click="onAction(button.type)"
                >
                    {{ button.text }}
                </button>
            </template>
            <template v-else>
                <AppButton
                    size="x-large"
                    @click="close"
                    theme="gray"
                >
                    {{ $t(buttonCancelText) }}
                </AppButton>
                <AppButton
                    size="x-large"
                    @click="onAction('confirm')"
                    :theme="buttonConfirmTheme"
                >
                    {{ $t(buttonConfirmText) }}
                </AppButton>
            </template>
        </div>
    </ModalWrapper>
</template>

<script>
import ModalWrapper from '@/components/partials/ModalWrapper.vue'
import AppButton from '@/components/partials/AppButton.vue'

export default {
    emits: ['action'],
    components: {
        ModalWrapper,
        AppButton
    },
    props: {
        modalWidth: {
            type: String,
            default: '422px'
        },
        buttonCancelText: {
            type: String,
            default: 'components.common.cancel'
        },
        buttonConfirmText: {
            type: String,
            default: 'components.common.yesIAgree'
        },
        buttonConfirmTheme: {
            type: String,
            default: 'danger'
        },
        modalActions: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onAction(action) {
            this.$emit('action', action)
        }
    }
}
</script>

<style lang="scss">
.app-confirm-modal {
    @apply tw-text-center;

    &__title {
        @apply tw-mb-8 tw-font-medium tw-text-[22px] tw-leading-[26px];
    }

    &__text {
        @apply tw-mb-12 tw-font-medium tw-font-secondary tw-text-secondary-50 tw-text-base tw-leading-5 tw-tracking-[0.005em];
        margin-bottom: 52px;
        font-size: 16px;
        line-height: 20px;
    }

    &__actions {
        @apply tw-flex tw-flex-nowrap tw-justify-center tw-gap-4;

        button {
            @apply tw-px-5 tw-whitespace-nowrap;

            &:only-child {
                @apply tw-w-full;
            }
        }
    }
}
</style>
